<template>
  <div class="notioncontent">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <FullPageContent :notiondata="data" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import FullPageContent from "@/components/notion/FullPageContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";

export default {
  name: "Lageplan",
  components: {
    Meta,
    FullPageContent,
    BlockContent,
  },
  data() {
    return {
      NotionPageID: "1698735bcab24402afa163bba05755de",
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
  },
};
</script>

<style lang="scss"></style>
